import React from "react"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

export default () => (
  <Layout>
    <SEO title="404: Not found" meta={[]} />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)
